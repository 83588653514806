/* eslint-disable indent */
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  ContentWrapper,
  ExelIcon,
  OutlinedLightButton,
  PrimaryButton,
  SelectButton
} from '@/components';
import {
  SelectReportingMethodBodyStyled,
  SelectReportingMethodFooterStyled,
  SelectReportingMethodStyled
} from './selectReportingMethod.styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IntervalI, INTERVALS, TSelectReportingMethodProps } from './type';
import CustomButtonGroup from './сustomButtonGroup';
import { SelectIntervalSection } from './selectInterval';
import { useGetReportDownloadTemplateQuery, usePostDemoReportMutation } from '@/services/api/reportTemplate';
import { ReportDownloadI } from '@/services/api/reportTemplate/types';

const boxStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
  gap: '2rem'
};

const radioStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
  gap: '.5rem'
};

const fontLabel = {
  fontSize: '14px',
  fontWeight: '500'
};

export const MOCK_COMPANY = {
  id: 2,
  name: 'Sandbox Company_US_1',
  platform: 'QUICKBOOKS_ONLINE',
  createdAt: '7/4/24, 12:25 PM',
  createdBy: 'Bohdan Alieksieiev',
  userEmail: 'bohdan.alieks@gmail.com'
};

const MIN_HOUR = 1;

const SelectReportingMethodExcel: FC<TSelectReportingMethodProps> = ({
  onBack,
  styleConfig,
  onApply,
  company = MOCK_COMPANY,
  templateId,
  defaultInterval,
  parameterValues
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [selectInterval, setSelectInterval] = useState<IntervalI>(
    defaultInterval?.selectInterval || INTERVALS.DAILY
  );
  const [hourOfDay, setHourOfDay] = useState<number>(defaultInterval?.hourOfDay || MIN_HOUR);
  const [dayOfWeek, setDayOFWeek] = useState<number[]>(defaultInterval?.dayOfWeek || [1]);
  const [dayOfMonth, setDayOfMonth] = useState<number[]>(defaultInterval?.dayOfMonth || [1]);
  const [selectCompanies, setSelectCompanies] = useState<number[]>([company.id]);
  const [downloadParams, setDownloadParams] = useState<ReportDownloadI | undefined>();
  const [postDemoReport, { data: reportTemplateBlob }] = usePostDemoReportMutation();

  useEffect(() => {
    if (downloadParams) {
      postDemoReport(downloadParams);
    }
  }, [downloadParams, postDemoReport]);


  useEffect(() => {
    if (reportTemplateBlob) {
      const url = reportTemplateBlob;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${downloadParams?.name || 'reportTemplate'}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloadParams(undefined);
    }
  }, [reportTemplateBlob]);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
    }
  };

  const getValueByDateType = (value: string | boolean | number | any, type: string) => {
    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return !!value;
      default:
        return value as string;
    }
  };

  const handleDownload = () => {
    if (file) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    } else {
      if (company && templateId) {
        setDownloadParams({
          companyId: company.id,
          platform: company.platform,
          reportTemplateId: templateId,
          reportParameters: parameterValues?.map(({ name, value, dataType, key }) => ({
            name,
            value: getValueByDateType(value, dataType),
            dataType,
            key
          })),
          name: company.name || company.platform,
          downloadId: Math.floor(Math.random() * (99999 - 1)) + 1
        });
        return;
      }
      // Handle case when no file is uploaded
      alert('No file uploaded');
    }
  };

  const onHandlerApply = () => {
    const result = {
      period: selectInterval,
      hourOfDay: hourOfDay,
      daysOfWeek: dayOfWeek,
      daysOfMonth: dayOfMonth,
      companyIds: selectCompanies,
      file: file
    };
    // TODO: add check required
    onApply && onApply(result);
  };

  const customDownButtons = useMemo(() => {
    return styleConfig?.customDownButtons || [];
  }, [styleConfig?.customDownButtons]);

  const resultForCustomButton = useMemo(() => {
    return {
      period: selectInterval,
      hourOfDay: hourOfDay,
      daysOfWeek: dayOfWeek,
      daysOfMonth: dayOfMonth,
      companyIds: selectCompanies,
      format: 'EXCEL',
      file: file
    };
  }, [selectInterval, hourOfDay, dayOfWeek, dayOfMonth, selectCompanies, file]);

  return (
    <Box sx={{ mt: styleConfig?.isNotMarginTop ? '0' : '2rem' }}>
      <ContentWrapper
        title={styleConfig?.isNotHeaderTitle ? undefined : 'Schedule & Customize'}
        isNotBorder={styleConfig?.isNotBorder}
      >
        <SelectReportingMethodStyled>
          <SelectReportingMethodBodyStyled>
            <Stack direction="column" gap="1.5rem">
              <SelectIntervalSection
                selectInterval={selectInterval}
                setSelectInterval={setSelectInterval}
                hourOfDay={hourOfDay}
                setHourOfDay={setHourOfDay}
                dayOfWeek={dayOfWeek}
                setDayOFWeek={setDayOFWeek}
                selectCompanies={selectCompanies}
                setSelectCompanies={setSelectCompanies}
                dayOfMonth={dayOfMonth}
                setDayOfMonth={setDayOfMonth}
                titleSx={fontLabel}
              />

              {/* <SelectExcelFile /> */}
              <Stack direction="column" gap="1rem">
                <Typography sx={fontLabel}>Output</Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                    gap: '.5rem'
                  }}
                >
                  <SelectButton
                    startIcon={<ExelIcon />}
                    onClick={handleDownload}
                    label="Download report"
                  />
                  <label htmlFor="upload-button">
                    <input
                      id="upload-button"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleUpload}
                      accept=".xlsx"
                    />
                    <SelectButton
                      startIcon={<ExelIcon />}
                      label={file ? `${file.name}` : 'Upload customized report'}
                      component="span"
                    />
                  </label>
                </Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{
                    textAlign: 'center',
                    maxWidth: '600px',
                    m: '2rem auto auto auto'
                  }}
                >
                  Download and preview the report. Customize the spreadsheet and upload it to update the report template.
                </Typography>
              </Stack>
            </Stack>
          </SelectReportingMethodBodyStyled>
          <SelectReportingMethodFooterStyled>
            {!customDownButtons.length && (
              <Stack direction="row" justifyContent="flex-end">
                <Stack direction="row" gap=".75rem" alignItems="center">
                  <OutlinedLightButton
                    label="Back"
                    startIcon={
                      <ArrowBackIcon
                        sx={{
                          color: '#747A82'
                        }}
                      />
                    }
                    onClick={onBack}
                  />
                  <PrimaryButton onClick={onHandlerApply} label="Apply" />
                </Stack>
              </Stack>
            )}
            <CustomButtonGroup
              customDownButtons={customDownButtons}
              value={resultForCustomButton}
            />
          </SelectReportingMethodFooterStyled>
        </SelectReportingMethodStyled>
      </ContentWrapper>
    </Box>
  );
};

export default SelectReportingMethodExcel;
